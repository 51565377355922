exports.components = {
  "component---src-pages-[event]-event-claim-tsx": () => import("./../../../src/pages/event/[event]/claim.tsx" /* webpackChunkName: "component---src-pages-[event]-event-claim-tsx" */),
  "component---src-pages-[event]-event-index-tsx": () => import("./../../../src/pages/event/[event]/index.tsx" /* webpackChunkName: "component---src-pages-[event]-event-index-tsx" */),
  "component---src-pages-[event]-event-quiz-tsx": () => import("./../../../src/pages/event/[event]/quiz.tsx" /* webpackChunkName: "component---src-pages-[event]-event-quiz-tsx" */),
  "component---src-pages-[event]-event-rewards-[reward]-tsx": () => import("./../../../src/pages/event/[event]/rewards/[reward].tsx" /* webpackChunkName: "component---src-pages-[event]-event-rewards-[reward]-tsx" */),
  "component---src-pages-blog-[author]-author-tsx": () => import("./../../../src/pages/blog/author/[author].tsx" /* webpackChunkName: "component---src-pages-blog-[author]-author-tsx" */),
  "component---src-pages-blog-[category]-[post]-tsx": () => import("./../../../src/pages/blog/[category]/[post].tsx" /* webpackChunkName: "component---src-pages-blog-[category]-[post]-tsx" */),
  "component---src-pages-blog-[category]-index-tsx": () => import("./../../../src/pages/blog/[category]/index.tsx" /* webpackChunkName: "component---src-pages-blog-[category]-index-tsx" */),
  "component---src-pages-blog-preview-index-tsx": () => import("./../../../src/pages/blog/preview/index.tsx" /* webpackChunkName: "component---src-pages-blog-preview-index-tsx" */),
  "component---src-pages-blog-search-index-tsx": () => import("./../../../src/pages/blog/search/index.tsx" /* webpackChunkName: "component---src-pages-blog-search-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */)
}

